import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import EveneerLogo from "../../assets/eveneer-logo.svg";

const Nav = () => {
    const location = useLocation();
    const [navState, setNavState] = useState({
        show: false
    });

    const toggleNav = () => {
        setNavState({ ...navState, show: !navState.show })
    }

    const items = [
        // {
        //     name: "About",
        //     slug: "/about",
        //     icon: <span className="material-icons-outlined mx-2">info</span>
        // },
        {
            name: "Featured Clients",
            slug: "/featured-clients",
            icon: <span className="material-icons-outlined mx-2">group</span>
        },
        {
            name: "Services",
            slug: "/services",
            icon: <span className="material-icons-outlined mx-2">data_object</span>
        },
        // {
        //     name: "Contact",
        //     slug: "/Contact",
        //     icon: <span className="material-icons-outlined mx-2">info</span>
        // },
    ]

    return <nav className={`flex flex-col lg:flex-row justify-start lg:justify-around bg-[#232323]/[0.95] ${navState.show ? "h-[100%]" : "h-[70px]"} z-10 lg:h-[70px] fixed top-0 w-[100%] overflow-hidden transition-all duration-300 ease-linear`}>
        <span className={`material-icons-outlined text-[2rem] my-auto absolute left-[15px] lg:hidden top-[19px] z-10 w-[2rem] ${navState.show ? "text-[#3B82F6]" : ""} transition-all duration-300 ease-linear`} onClick={toggleNav}>menu</span>
        <Link to="/" className="lg:w-[30%] flex flex-row mx-auto my-[15px] lg:my-auto drop-shadow-[0px_0px_10px_#3B82F6]">
            <img src={EveneerLogo} className="h-[40px] ml-auto lg:ml-0 drop-shadow-[0px_0px_10px_#3B82F6]" alt="eveneer-logo" />
            <span className="my-auto mr-auto text-[1.5rem] font-bold shadow-[0.1rem_0.1rem_#EF4444,-0.1rem_-0.1rem_#3B82F6] px-3 ml-3">Eveneer</span>
        </Link>
        <div className="w-[100%] lg:w-[40%] flex flex-col lg:flex-row justify-end gap-10 lg:gap-4 lg:mr-20 mt-20 lg:my-auto">
            {items.map((item, itemIndex) => {
                return <Link to={item.slug} className={`my-auto mx-auto lg:mx-0 flex hover:text-[#EF4444] ${item.slug === location.pathname ? "!text-[#3B82F6]" : ""}`} key={`nav-item-${itemIndex}`} onClick={toggleNav}>
                    {item.icon}
                    {item.name}
                </Link>
            })}
        </div>
    </nav>
}

export default Nav;