import EveneerLogo from "../../assets/eveneer-logo.svg"
import Typewriter from "typewriter-effect";

import { motion } from "framer-motion";

const Landing = () => {
    return <motion.div
        className="w-[100%] min-h-[100vh] flex flex-row md:flex-col justify-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
    >
        <div className="w-[90%] md:w-[90%] lg:w-[80%] xl:w-[70%] flex flex-col md:flex-row md:my-auto h-[100vh] md:h-[700px] justify-center md:justify-start  mx-auto">
            <div className="relative w-[100%] md:w-[15vw] md:h-[15vw] md:my-auto opacity-0 landingLogo drop-shadow-[0px_0px_10px_#3B82F6]">
                <img src={EveneerLogo} alt="eveneer-logo" className="w-[50%] mx-auto md:w-[100%]" />
            </div>

            <div className="w-[100%] text-center lg:text-left md:w-[50vw] xl:w-[60%] my-20 md:my-auto md:ml-20">
                <h1 className="text-[1.5rem] lg:text-[3rem]">
                    <Typewriter
                        onInit={typewriter => {
                            typewriter
                                .pauseFor(2000)
                                .typeString("Welcome to Eveneer!!")
                                .start();
                        }}
                        options={{ cursor: "" }}
                    />
                </h1>
                <div className="h-1 w-[0%] shadow-[0.1rem_0.1rem_#EF4444,-0.1rem_-0.1rem_#3B82F6] md:shadow-[0.15rem_0.15rem_#EF4444,-0.15rem_-0.15rem_#3B82F6] landingTitleBorder" />
                <h3 className="text-[0.9rem] lg:text-[1.3rem] tagline opacity-0 mt-3 md:mt-5">
                    Mapping problems to solutions
                </h3>
            </div>
        </div>
    </motion.div>
}

export default Landing;