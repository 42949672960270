import { Routes, Route, useLocation } from "react-router-dom";

import { AnimatePresence } from "framer-motion";

import Landing from "../../pages/Landing/Landing";
import About from "../../pages/About/About";
import FeaturedClients from "../../pages/FeaturedClients/FeaturedClients";
import Services from "../../pages/Services/Services";
import Team from "../../pages/Team/Team";
import NotFound from "../../pages/NotFound/NotFound";

const AnimatedRoutes = () => {
    const location = useLocation();

    return <AnimatePresence>
        <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Landing />} />
            <Route path="/about" element={<About />} />
            <Route path="/featured-clients" element={<FeaturedClients />} />
            <Route path="/services" element={<Services />} />
            <Route path="/team" element={<Team />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    </AnimatePresence>
}

export default AnimatedRoutes;