const NotFound = () => {
    return <div className="w-[100%] h-[100vh] flex flex-col justify-center">
        <div className="w-[90%] md:w-[50%] mx-auto">
            <span className="material-icons-outlined text-center text-[20rem] w-[100%]">sentiment_very_dissatisfied</span>
            <h2 className="text-[1.5rem] text-center">Your power has exceeded our expectations</h2>
            <h2 className="text-[2rem] text-center mt-20">Err: 404</h2>
        </div>
    </div >
}

export default NotFound;