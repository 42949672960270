import { motion } from "framer-motion"
import Typewriter from "typewriter-effect";

import WebLogo from "../../assets/web-logo.svg";
import LaravelLogo from "../../assets/laravel-logo.svg";
import ReactLogo from "../../assets/react-logo.svg";
import NodeLogo from "../../assets/node-logo.svg";
import TailwindLogo from "../../assets/tailwind-logo.svg";

import MobileLogo from "../../assets/mobile-logo.svg";
import AndroidLogo from "../../assets/android-logo.svg";
import IosLogo from "../../assets/ios-logo.svg";

import DesktopLogo from "../../assets/desktop-logo.svg";
import LinuxLogo from "../../assets/linux-logo.svg";
import WindowsLogo from "../../assets/windows-logo.svg";
import AppleLogo from "../../assets/apple-logo.svg";

import CryptoLogo from "../../assets/crypto-logo.svg";
import BlockchainLogo from "../../assets/blockchain-logo.svg";
import CryptographyLogo from "../../assets/cryptography-logo.svg";

const Services = () => {
    const services = [
        {
            name: "Web App Development",
            logo: WebLogo,
            description: "In this age of information, having a cloud based infrastructure is a must. The basis of that, the web.",
            platforms: [LaravelLogo, ReactLogo, NodeLogo, TailwindLogo],
            platformNames: ["Laravel", "React JS", "Node JS", "Tailwind CSS"],
        },
        {
            name: "Mobile App Development",
            logo: MobileLogo,
            description: "Maximize your reach to more users; faster, better, and most importantly, at their convenience.",
            platforms: [AndroidLogo, IosLogo],
            platformNames: ["Android OS", "iOS"],
        },
        {
            name: "Desktop App Development",
            logo: DesktopLogo,
            description: "Desktop apps helps optimize your work efficiency by driving focus to single tasks.",
            platforms: [LinuxLogo, AppleLogo, WindowsLogo],
            platformNames: ["Linux and distro", "Apple OS", "Windows OS"],
        },
        {
            name: "Customized Information Security",
            logo: CryptoLogo,
            description: "Take your application/infrastructure security to the next level with blockchain, custom encryption, digital signing and/or hashing algorithms.",
            platforms: [BlockchainLogo, CryptographyLogo],
            platformNames: ["Blockchain", "Cryptography"],
        },
    ];

    return <motion.div
        className="w-[100%] min-h-[100vh] flex flex-col justify-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
    >
        <div className="w-[90%] md:w-[90%] lg:w-[80%] xl:w-[70%] mx-auto min-h-[100vh] py-[100px]">
            <h2 className="text-[1.2rem] md:text-[2rem] shadow-[0.1rem_0.1rem_#EF4444,-0.1rem_-0.1rem_#3B82F6] md:shadow-[0.2rem_0.2rem_#EF4444,-0.2rem_-0.2rem_#3B82F6] px-10 py-5">
                <Typewriter
                    onInit={typewriter => {
                        typewriter
                            .typeString("Services Offered")
                            .start();
                    }}
                    options={{ cursor: "" }}
                />
            </h2>
            <p className="my-10 pageTagline opacity-0 text-justify">
                We try to be your one stop shop solution for your software needs. Increase your business value through our services!
            </p>
            <div className="flex flex-row flex-wrap justify-between">
                {services.map((service, serviceIndex) => {
                    return <div className="w-[100%] md:w-[47%] mt-20 bg-[#232323] flex flex-col shadow-[0.1rem_0.1rem_#EF4444,-0.1rem_-0.1rem_#3B82F6] md:shadow-[0.1rem_0.1rem_#EF4444,-0.1rem_-0.1rem_#3B82F6] p-5" key={`s-${serviceIndex}`}>
                        <div className="w-[100%] md:w-[50%] mx-auto drop-shadow-[0px_0px_10px_#3B82F6]">
                            <img src={service.logo} alt={`${service.name}`} className="w-[50%] mx-auto drop-shadow-[0px_0px_10px_#3B82F6]" />
                        </div>
                        <div className="border-b-2 my-5"></div>
                        <h3 className="text-[1.2rem]">{service.name}</h3>
                        <p className="mb-10 text-[0.9rem]">{service.description}</p>
                        <div className="border-b-2 my-5"></div>

                        <div className="flex flex-row my-5 justify-around drop-shadow-[0px_0px_10px_#EF4444]">
                            {service.platforms.map((platform, platformIndex) => <img src={platform} className="w-[15%] drop-shadow-[0px_0px_10px_#EF4444]" alt={`${service.platformNames[platformIndex]}`} key={`p-${serviceIndex}-${platformIndex}`} />)}
                        </div>
                        <div className="border-b-2 my-5"></div>
                    </div>
                })}
            </div>
        </div>
    </motion.div>
}

export default Services;