import { BrowserRouter as Router } from "react-router-dom";

import Nav from "./components/Nav/Nav";
import AnimatedRoutes from "./components/AnimatedRoutes/AnimatedRoutes";

const App = () => {
    return <div className="w-[100vw] min-h-[100vh] bg-[#171717]">
        <Router>
            <Nav />
            <AnimatedRoutes />
        </Router>
    </div>
}

export default App;
