import { motion } from "framer-motion"

const About = () => {
    return <motion.div
        className="w-[100%] h-[100vh] flex flex-col justify-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
    >
        <p className="mx-auto">About</p>
    </motion.div>
}

export default About;