import { motion } from "framer-motion"
import Typewriter from "typewriter-effect";

import BracuLogo from "../../assets/bracu-logo.svg";
import AmlLogo from "../../assets/aml-logo.svg";
import TimanityLogo from "../../assets/timanity-logo.svg";

const FeaturedClients = () => {
    const clients = [
        {
            logo: BracuLogo,
            name: "Department of CSE, Brac University",
            description: [
                "Over the years, we have worked with the Department of CSE, Brac University to develop several software and provided them with services to best serve their students."
            ],
            works: [
                "Online Utility Tool for faculty members during online classes",
                "Anonymous and verifiable student evaluation collection portal",
                "Server infrastructure and maintenance",
            ]
        },
        {
            logo: AmlLogo,
            name: "Office of MD, Abdul Monem Limited",
            description: [
                "Since the second quater of 2021, we assessed the existing business processes being utilized at Abdul Monem Limited and suggested potential adoption of software and frameworks to enhance the processes and derive more business value."
            ],
            works: [
                "Software integration with existing business processes",
            ]
        },
        {
            logo: TimanityLogo,
            name: "Timanity MiniGames, Timanity Initiative",
            description: [
                "Near the end of the first quarter of 2022 we began working, and are currently still working, on developing an ad-tech based solution for Timanity Initative, which is a non-profit initative."
            ],
            works: [
                "Android and iOS app for end users",
                "Game panel for admin users",
            ]
        }
    ];

    return <motion.div
        className="w-[100%] min-h-[100vh] flex flex-col justify-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
    >
        <div className="w-[90%] md:w-[90%] lg:w-[80%] xl:w-[70%] mx-auto min-h-[100vh] pt-[100px]">
            <h2 className="text-[1.2rem] md:text-[2rem] shadow-[0.1rem_0.1rem_#EF4444,-0.1rem_-0.1rem_#3B82F6] md:shadow-[0.2rem_0.2rem_#EF4444,-0.2rem_-0.2rem_#3B82F6] px-10 py-5">
                <Typewriter
                    onInit={typewriter => {
                        typewriter
                            .typeString("Featured Clients")
                            .start();
                    }}
                    options={{ cursor: "" }}
                />
            </h2>
            <p className="my-10 pageTagline opacity-0 text-justify">
                While we have worked and are working with several clients, unfortunately we cannot showcase all the names due to security and/or legal concerns.
                These are some of our showcasable clients and works that are complete or we are currently working on.
            </p>

            {clients.map((client, clientIndex) => {
                return <div className={`flex my-20 flex-col md:flex-row w-[100%] bg-[#232323] px-5 py-10 gap-4 md:justify-around shadow-[0.1rem_0.1rem_#EF4444,-0.1rem_-0.1rem_#3B82F6] md:shadow-[0.1rem_0.1rem_#EF4444,-0.1rem_-0.1rem_#3B82F6]`} key={`c-${clientIndex}`}>
                    <div className="md:w-[20%] my-auto px-5 drop-shadow-[0px_0px_10px_#3B82F6]">
                        <img src={client.logo} alt={`${client.name}`} className="w-[50%] md:w-[100%] mx-auto mb-5 drop-shadow-[0px_0px_10px_#3B82F6]" />
                    </div>
                    <div className="w-[100%] md:w-[70%]">
                        <h2 className="text-[1.2rem] border-b-2 mb-2">{client.name}</h2>
                        {client.description.map((des, desIndex) => <p className="my-3 text-[0.9rem]" key={`d-${clientIndex}-${desIndex}`}>{des}</p>)}
                        <p className="my-3 text-[0.9rem]">{client.works.length === 1 ?
                            "Our main work revolved around" :
                            "Some of the services that we provided are"
                        }</p>
                        <ul className="my-5 text-[0.9rem]">
                            {client.works.map((work, workIndex) => <li className="px-2 py-1 my-2 ml-10" key={`w-${clientIndex}-${workIndex}`}><span className="font-bold mr-3">&gt;</span> {work}</li>)}
                        </ul>
                    </div>
                </div>
            })}
        </div>
    </motion.div>
}

export default FeaturedClients;